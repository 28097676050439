<template>
  <div class="login">
    <div class="loginForm">
      <p class="title">
        用户注册
      </p>
      <el-form ref="form"
               :model="form"
               :rules="rules">
        <el-form-item prop="username">
          <el-input v-model="form.username"
                    placeholder="请输入账号" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password"
                    type="password"
                    autocomplete="new-password"
                    placeholder="请输入密码" />
        </el-form-item>
        <el-form-item prop="confirmPwd">
          <el-input v-model="form.confirmPwd"
                    type="password"
                    autocomplete="new-password"
                    placeholder="请确认密码" />
        </el-form-item>
      </el-form>
      <el-button class="loginBtn"
                 @click="register">
        完成注册
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    const checkPwd = ((rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error('两次密码输入不一致!'))
      } else {
        callback()
      }
    })
    return {
      form: {
        username: '',
        password: '',
        confirmPwd: ''
      },
      rules: {
        username: { required: true, message: "请输入账号", trigger: "blur" },
        password: { required: true, message: "请输入密码", trigger: "blur" },
        confirmPwd:
          [{ required: true, message: "请确认密码", trigger: "blur" },
          { validator: checkPwd, trigger: "blur" }]
      },
      repeat: false
    }
  },
  methods: {
    register () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!localStorage.userList) {
            // 系统第一次注册
            localStorage.setItem('userList', JSON.stringify(new Array()))
            let userList = JSON.parse(localStorage.getItem('userList'))
            userList.push(this.form)
            localStorage.setItem('userList', JSON.stringify(userList))
            this.$message.success('注册成功')
            this.$router.push('/')
          }
          else {
            console.log(1);
            let userList = JSON.parse(localStorage.getItem('userList'))
            userList.map((item) => {
              if (item.username === this.form.username) {
                //账号已存在
                this.$message.error('账号已存在')
                this.repeat = true
              }
            })
            if (!this.repeat) {
              // 新用户注册
              let userList = JSON.parse(localStorage.getItem('userList'))
              userList.push(this.form)
              localStorage.setItem('userList', JSON.stringify(userList))
              this.$message.success('注册成功')
              localStorage.setItem('loginName', this.form.username)
              this.$router.push('/')
            }
          }
          console.log(JSON.parse(localStorage.getItem('userList')), 'userList');
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: steelblue;
}
.loginForm {
  width: 440px;
  padding: 40px;
  background: #fff;
  box-shadow: 0 0 29px 3px #0f5299;
  border-radius: 6px;
}
.loginBtn {
  width: 100%;
  background: #0062ff;
  color: #fff;
  font-size: 16px;
  margin-top: 15px;
}
.title {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0;
}
</style>